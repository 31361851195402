import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class BudgetService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async getBudgetByParams (filters) {
		const response = await this.axios.get("budget/getBudgetByParams", { params: { filters } });
		return response.data;
	}

	async createBudget (payload) {
		const response = await this.axios.post("budget/createBudget", payload);
		return response.data;
	}

	async updateBudget (payload) {
		const response = await this.axios.patch("budget/updateBudget", payload);
		return response.data;
	}

	async deleteBudget (payload) {
		const response = await this.axios.patch("budget/deleteBudget", payload);
		return response.data;
	}
}
